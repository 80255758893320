import { MsalAuthProvider, LoginType } from 'react-aad-msal';

export const prodId = '31c5829a-b702-49b3-a060-24f3cd42d3ab';
export const preProdId = 'a3d39467-877f-4e75-84a0-128f9666b73c';

const config: any = {
    auth: {
        authority: 'https://login.microsoftonline.com/75584e34-72c0-4772-b459-a9fe78fec27c',
        clientId: '31c5829a-b702-49b3-a060-24f3cd42d3ab', //process.env.REACT_APP_ENV === 'PROD' ? prodId : preProdId,
        postLogoutRedirectUri: window.location.origin,
        redirectUri: window.location.origin,
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
    },
};

const authenticationParameters = {
    // scopes: [
    // 	"user.read",
    // 	"https://realelivetech.onmicrosoft.com/reale-wft-mail-routing/user.read",
    // ],
};

// Options
const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin,
};

export const authProvider = new MsalAuthProvider(config, authenticationParameters, options);
